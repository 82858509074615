import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, navigate } from "gatsby"
import { useAppDispatch, useAppSelector } from "../hooks/redusHooks"
import { selectUserBaseInfo } from "../store/selector/user.selector"
import { Button, Col, Dropdown, FormControl, InputGroup, Row } from "react-bootstrap"
import { logout } from "../service/loginService"
import { logoutClearLocal } from "../util/commonUtil"
import { setUserBaseInfo, setUserPermission } from "../store/reducers/user.reducer"
import { canAddAnnouncement, canAddArticle, canAddNews, canAddQuestion, canAdminAccess } from "../store/selector/permission.selector"
import logo from '../images/logo.png'
import HeaderNav from "./components/HeaderNav"
import { BellOutlined, FormOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons"
import { CATEGORY_MODULE } from "../typing/enum"
import { setCategoryId, setHomeCurQuery } from "../store/reducers/home.reducer"

const PRE_FIX = 'APP_HEADER'

interface IProps {
  path: string;
  model?: CATEGORY_MODULE | 'HOME';
}

const Header: React.FC<IProps> = ({ path, model }) => {
  const userBaseInfo = useAppSelector(selectUserBaseInfo);
  const isAdminAccess = useAppSelector(canAdminAccess)
  const haveAddArticle = useAppSelector(canAddArticle)
  const haveAddAnnouncement = useAppSelector(canAddAnnouncement)
  const haveAddQuestion = useAppSelector(canAddQuestion)
  const haveAddNews = useAppSelector(canAddNews)
  const dispatch = useAppDispatch()
  const [curQuery, setCurQuery] = useState<string>()
  const [searchMouseEnter, setSearchMouseEnter] = useState<boolean>(false)

  const logoutHandler = useCallback(async (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.preventDefault();
    await logout();
    logoutClearLocal();
    dispatch(setUserBaseInfo(null));
    dispatch(setUserPermission([]));
    navigate("/login");
    // window.location.replace(window.location.href);
  }, [])

  const handleFilter = useCallback((val: string) => {
    setCurQuery(val)
  }, []);

  const handleKeydown = useCallback((key: string) => {
    if (key === 'Enter') {
      dispatch(setHomeCurQuery({ keyword: curQuery || '' }))
    }
  }, [curQuery]);

  const searchClickHandler = useCallback(() => {
    dispatch(setHomeCurQuery({ keyword: curQuery || '' }))
  }, [curQuery]);

  useEffect(() => {
    setCurQuery('')
    dispatch(setCategoryId(undefined))
    dispatch(setHomeCurQuery({ keyword: '' }))
  }, [model])

  const handleMouseEnter = useCallback(() => {
    setSearchMouseEnter(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setSearchMouseEnter(false)
  }, [])

  const LoggedOutView = useMemo(() => {
    if (!userBaseInfo) {
      return (
        <ul className={`nav navbar-nav pull-xs-right ${PRE_FIX}-navbar-nav`}>
  
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              登录
            </Link>
          </li>
  
          <li className="nav-item">
            <Link to="/register" className="nav-link">
              注册
            </Link>
          </li>
  
        </ul>
      );
    }
    return null;
  }, [userBaseInfo]);
  
  const LoggedInView = useMemo(() => {
    if (userBaseInfo) {
      let showAddArticle = false;
      switch(model) {
        case CATEGORY_MODULE.ANNOUNCEMENT:
          showAddArticle = haveAddAnnouncement;
          break;
        case CATEGORY_MODULE.ARTICLE:
          showAddArticle = haveAddArticle;
          break;
        case CATEGORY_MODULE.NEWS:
          showAddArticle = haveAddNews;
          break;
        case CATEGORY_MODULE.QUESTION:
        default:
          showAddArticle = haveAddQuestion;
          break;  
      }

      return (
        <ul className={`nav navbar-nav pull-xs-right ${PRE_FIX}-navbar-nav`}>
          {
            showAddArticle && <li className="nav-item">
              <Link
                to="/editor"
                state={{
                  model: model || CATEGORY_MODULE.QUESTION,
                }}
                className="icon-link d-flex align-items-center justify-content-center p-0 position-relative nav-link"
              >
                <FormOutlined />
              </Link>
            </li>
          }
          {/* {
            isAdminAccess && <li className="nav-item">
              <Link to="/admin/user" className="icon-link d-flex align-items-center justify-content-center p-0 position-relative nav-link">
                <SettingOutlined />
              </Link>
            </li>
          } */}
          <li className="nav-item">
            <Link to="/user/notifications" className="icon-link d-flex align-items-center justify-content-center p-0 position-relative nav-link">
              <BellOutlined />
            </Link>
          </li>
          <li className="nav-item">
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              as="a"
              role="button"
              className="nav-link nav-avatar-name">
              {userBaseInfo.avatar && <img src={userBaseInfo.avatar} className="user-pic" />}
              <span className="nav-nickname">{userBaseInfo.nickname}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                  <Link
                    to={`/user/personal`}
                    state={{ userId: userBaseInfo.id }}
                    className="nav-link">
                    主页
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                  <Link
                    to={`/user/setting/profile`}
                    className="nav-link">
                    个人资料
                </Link>
              </Dropdown.Item>
              {
                (isAdminAccess || userBaseInfo?.roleCode==='Moderator') &&
                <Dropdown.Item>
                  <Link
                    to={`/admin/news`}
                    className="nav-link">
                    后台管理
                </Link>
              </Dropdown.Item>
              }
              
              <Dropdown.Item>
                  <Link
                    to={`/setting`}
                    className="nav-link">
                    设置
                </Link>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logoutHandler}>
                退出
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </li>
  
        </ul>
      );
    }
  
    return null;
  }, [model, userBaseInfo, haveAddAnnouncement, haveAddArticle, haveAddQuestion, isAdminAccess]);
  
  return <nav className={`${PRE_FIX}`}>
    <div className="container">
      <img src={logo} width={64} height={64} />
      <HeaderNav model={model} />
      <Row style={{width: '35%', minWidth: '410px'}}>
        <Col lg={6} style={{ paddingRight: '0px' }}>
        {model && model !== 'HOME' &&
          <InputGroup
            className={`${PRE_FIX}-search ${ model ? PRE_FIX + '-search-show' : PRE_FIX + '-search-hide'}`}
            // onMouseLeave={handleMouseLeave}
          >
            <FormControl
              type="search"
              placeholder="搜索"
              className={`placeholder-search ${searchMouseEnter || true ? 'search-show' : 'search-hidden'}`}
              value={curQuery}
              onChange={(e) => handleFilter(e.target.value)}
              onKeyDown={(e) => handleKeydown(e.key)}
            />
            <Button variant="light" className={`search-icon-button ${searchMouseEnter || true ? 'search-icon-button-show' : ''}`} onClick={searchClickHandler}>
              <SearchOutlined />
            </Button>
            {/* {
              searchMouseEnter ? <Button variant="light" className={`search-icon-button ${searchMouseEnter ? 'search-icon-button-show' : ''}`} onClick={searchClickHandler}>
                <SearchOutlined />
              </Button> : <div className="search-icon-button" onMouseEnter={handleMouseEnter}>
                <SearchOutlined />
              </div>
            } */}
          </InputGroup>
        }
        </Col>
        <Col lg={6}>
          {LoggedOutView}
          {LoggedInView}
        </Col>
      </Row>
    </div>
  </nav>
}

export default Header
